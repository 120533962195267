import {
  request
} from "../utils/axios.js";

export default {
  // 业绩达标提成 ==> 达标规则
  initSaveQualify(params = {}) {
    return request('GET', `/qualify/init_save_qualify`, params)
  },

  getQualifyList(params = {}) {
    return request('GET', `/qualify/get_qualify_list`, params)
  },

  saveQualify(params = {}) {
    return request('POST', `/qualify/save_qualify`, params)
  },

  deleteQualify(params = {}) {
    return request('GET', `/qualify/delete_qualify`, params)
  },


  queryQualifyData(params = {}) {
    return request('GET', `/qualify/query_qualify_data`, params)
  },


  applyQualifyData(params = {}) {
    return request('POST', `/qualify/apply_qualify_data`, params)
  },


}