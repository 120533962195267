<template>
  <div class="salary-quailfy">
    <!-- start 条件过滤区 -->
    <div class="df-row">
      <div class="df-col">
        <!-- 日期选择器 -->
        <el-date-picker
          v-model="multipleDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
          value-format="yyyy-MM-dd"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>
      <div class="df-col">
        <!-- 达标规则选择器 -->
        <el-select
          v-model="submitData.qualify_guid"
          placeholder="请选择达标规则"
          size="mini"
        >
          <el-option
            v-for="item in qualifyList"
            :key="item.QualifyGuid"
            :label="item.QualifyName"
            :value="item.QualifyGuid"
          >
          </el-option>
        </el-select>
      </div>
      <div class="df-col">
        <!-- 查询按钮 -->
        <el-button type="primary" size="mini" @click="onSearch">
          查询
        </el-button>

        <!-- 执行修改按钮 -->
        <el-button
          type="primary"
          size="mini"
          :disabled="submitButton"
          @click="onSubmitFrom"
        >
          执行修改
        </el-button>

        <!-- 执行全部规则修改按钮 -->
        <el-button
          type="primary"
          size="mini"
          :disabled="submitAllButton"
          @click="onSubmitFromAll"
        >
          执行全部规则修改
        </el-button>

        <!-- 修改达标规则按钮 -->
        <el-button
          type="primary"
          size="mini"
          @click="onShowSettingQualifyDialog"
        >
          修改达标规则
        </el-button>
      </div>
    </div>
    <!-- end 条件过滤区 -->

    <!-- start 员工达标表格 -->
    <div class="qualify-table">
      <el-table
        border
        stripe
        :data="tableData"
        :max-height="tableMaxHeight"
        v-loading="loading"
      >
        <el-table-column prop="EmplNo" label="员工编号"></el-table-column>
        <el-table-column prop="EmplName" label="员工姓名"></el-table-column>
        <el-table-column prop="Perfor" label="业绩"></el-table-column>
        <el-table-column prop="AssignPerfor" label="指定业绩"></el-table-column>
        <el-table-column
          prop="NotAssignPerfor"
          label="轮牌业绩"
        ></el-table-column>
        <el-table-column prop="CashPerfor" label="现金业绩"></el-table-column>
        <el-table-column
          prop="OpenKeepCashPerfor"
          label="开充卡现金业绩"
        ></el-table-column>
      </el-table>
    </div>
    <!-- end 员工达标表格 -->

    <!-- start 修改达标规则对话框 -->
    <SettingSalaryDialog
      ref="settingSalaryDialog"
      :tableData="qualifyList"
      @success="initQuqlifyData"
    ></SettingSalaryDialog>
    <!-- end 修改达标规则对话框 -->
  </div>
</template>

<script>
import Qualify from "@/api/qualify.js";
import SettingSalaryDialog from "./components/setting-qualify-dialog.vue";
export default {
  components: { SettingSalaryDialog },

  data() {
    return {
      tableMaxHeight: "", // 表格最大高度
      submitData: {
        // 提交数据
        begin_date: "", // 开始时间
        end_date: "", // 结束时间
        qualify_guid: "", // 规则id
      },
      multipleDate: [], // 日期数组
      tableData: [], // 达标提成表数据
      qualifyList: [], // 达标规则列表
      loading: false, // 表格加载
      submitButton: true, // 执行修改禁用
      submitAllButton: false, // 执行全部规则修改禁用
    };
  },

  created() {
    this.getResize();
  },

  async mounted() {
    window.addEventListener("resize", this.getResize);

    await this.initDateState()
    await this.initQuqlifyData();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68);
    },

    // 初始化达标规则列表
    async initQuqlifyData() {
      let { data } = await Qualify.getQualifyList();
      this.qualifyList = data;
    },

    // 查询符合达标规则的员工列表
    async queryQualify(submitData) {
      let res = await Qualify.queryQualifyData(submitData);
      return res;
    },

    // 查询按钮事件
    async onSearch() {
      this.loading = true;
      try {
        console.log(this.submitData);
        let { data } = await this.queryQualify(this.submitData);

        this.tableData = data;
        this.submitButton = data == "" ? true : false;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 初始化日期状态
    async initDateState() {
      let { beginDay, endDay } = await this.$store.dispatch("onGetDateState");
      this.multipleDate = [beginDay, endDay];
      this.submitData.begin_date = beginDay;
      this.submitData.end_date = endDay;
    },

    // 日期选择器改变时间
    onChangeDate(event) {
      this.$store.dispatch("onChangeDateState", { dayTime: event });
      this.initDateState();
    },

    // 打开修改达标规则对话框
    onShowSettingQualifyDialog() {
      this.$refs.settingSalaryDialog.onShowDialog();
    },

    // 提交修改规则
    async applyQualifyData(tableData, params) {
      let { qualify_guid, begin_date, end_date } = params;
      let arr = [];
      await tableData.forEach((item) => arr.push(item.EmplGuid));

      let submitData = await {
        QualifyGuid: qualify_guid,
        BeginDate: begin_date,
        EndDate: end_date,
        EmplGuids: arr,
      };

      let res = await Qualify.applyQualifyData(submitData);
      return res;
    },

    // 执行修改按钮点击事件
    async onSubmitFrom() {
      this.submitButton = true;

      try {
        let { errcode } = await this.applyQualifyData(
          this.tableData,
          this.submitData
        );

        if (errcode == 0) {
          this.$message.success("修改提交成功");
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.submitButton = false;
      }
    },

    // 执行全部规则修改按钮点击事件
    async onSubmitFromAll() {
      let { qualifyList, submitData } = this;
      let { begin_date, end_date } = submitData;
      // console.log(qualifyList, submitData, tableData);
      this.submitAllButton = true;

      try {
        let sum = 0
        qualifyList.forEach(async (item, index) => {
          let qualifySubmitData = {
            begin_date,
            end_date,
            qualify_guid: item.QualifyGuid,
          };
          let qualifyData = await this.queryQualify(qualifySubmitData);

          if (qualifyData.data.length > 0) {
            await this.applyQualifyData(qualifyData.data, qualifySubmitData);
            sum ++
          }

          if (qualifyList.length == index + 1) {
            sum > 0 ? this.$message.success("修改提交成功") : this.$message.info("无达标员工可修改")
            this.submitAllButton = false;
          }
        });
      } catch (err) {
        this.submitAllButton = false;
        console.log(err);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.df-row {
  margin-top: 20px;
}
</style>
