<template>
  <div class="setting-qualify-dialog">
    <!-- start 修改达标规则对话框 -->
    <el-dialog
      title="修改达标规则"
      :visible.sync="showQualifyDialog"
      width="90%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <div>
        <!-- 新增一行按钮 -->
        <el-button size="small" @click="onShowAddDialog('新增')"
          >新增规则</el-button
        >
      </div>

      <!-- start 修改达标规则表格 -->
      <div>
        <el-table v-loading="loading" :data="tableData" height="500">
          <el-table-column label="规则名称" prop="QualifyName" width="200">
          </el-table-column>
          <el-table-column label="职位" prop="DutyName"> </el-table-column>
          <el-table-column label="级别" prop="LevelName"> </el-table-column>
          <el-table-column label="项目基类" prop="ItemBasicName">
          </el-table-column>
          <el-table-column label="客户类型" prop="CustomerName">
          </el-table-column>
          <el-table-column label="劳动业绩" prop="PerforBegin">
            <template slot-scope="scope">
              {{ scope.row.PerforBegin }} 至<br />{{ scope.row.PerforEnd }}
            </template>
          </el-table-column>
          <!-- <el-table-column label="劳动业绩" prop="PerforBegin"></el-table-column>
          <el-table-column label="劳动业绩结束" prop="PerforEnd">
          </el-table-column> -->
          <el-table-column label="劳动现金业绩">
            <template slot-scope="scope">
              {{ scope.row.CashPerforBegin }} 至<br />{{
                scope.row.CashPerforEnd
              }}
            </template>
          </el-table-column>
          <!-- <el-table-column label="劳动现金业绩开始" prop="CashPerforBegin">
          </el-table-column>
          <el-table-column
            label="劳动现金业绩结束"
            prop="CashPerforEnd"
          ></el-table-column> -->
          <el-table-column label="劳动指定业绩">
            <template slot-scope="scope">
              {{ scope.row.AssignPerforBegin }} 至<br />{{
                scope.row.AssignPerforEnd
              }}
            </template>
          </el-table-column>
          <el-table-column label="劳动轮牌业绩">
            <template slot-scope="scope">
              {{ scope.row.NotAssignPerforBegin }} 至<br />{{
                scope.row.NotAssignPerforEnd
              }}
            </template>
          </el-table-column>
          <!-- <el-table-column label="劳动指定业绩开始" prop="AssignPerforBegin">
          </el-table-column>
          <el-table-column label="劳动指定业绩结束" prop="AssignPerforEnd">
          </el-table-column>
          <el-table-column label="劳动轮牌业绩开始" prop="NotAssignPerforBegin">
          </el-table-column>
          <el-table-column label="劳动轮牌业绩结束" prop="NotAssignPerforEnd">
          </el-table-column> -->
          <el-table-column label="开充卡现金业绩">
            <template slot-scope="scope">
              {{ scope.row.OpenKeepCashPerforBegin }} 至<br />{{
                scope.row.OpenKeepCashPerforEnd
              }}
            </template>
          </el-table-column>
          <!-- <el-table-column
            label="开卡现金业绩开始"
            prop="OpenKeepCashPerforBegin"
          >
          </el-table-column>
          <el-table-column
            label="开卡现金业绩结束"
            prop="OpenKeepCashPerforEnd"
          >
          </el-table-column> -->
          <el-table-column label="手动修改过的不修改">
            <template slot-scope="scope">
              {{ scope.row.IgnoreManualModified ? "不修改" : "修改" }}
            </template>
          </el-table-column>
          <el-table-column label="按金额设置提成的不修改">
            <template slot-scope="scope">
              {{ scope.row.IgnoreSetRoyaByMoney ? "不修改" : "修改" }}
            </template>
          </el-table-column>
          <el-table-column label="轮牌业绩提成百分比模式">
            <template slot-scope="scope">
              {{ scope.row.PercentMode ? "增加" : "覆盖" }}
            </template>
          </el-table-column>
          <el-table-column label="轮牌业绩提成百分率(%)" prop="AwardPercent">
          </el-table-column>
          <el-table-column label="指定业绩提成百分率(%)" prop="AwardPercentAssign">
          </el-table-column>
          <el-table-column label="操作" class="button-box" width="150">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="onShowAddDialog('修改', scope.row)"
              >
                修改
              </el-button>

              <el-button
                type="danger"
                size="mini"
                :disabled="deleteDisable"
                @click="onDeleteSalary(scope.row.QualifyGuid)"
              >
                移除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- end 修改达标规则表格 -->
    </el-dialog>
    <!-- end 修改达标规则对话框 -->

    <AddQualifyDialog
      ref="addQualifyDialog"
      v-bind="$attrs"
      v-on="$listeners"
    ></AddQualifyDialog>
  </div>
</template>

<script>
import Qualify from "@/api/qualify.js";
import AddQualifyDialog from "./add-qualify-dialog.vue";
export default {
  components: { AddQualifyDialog },

  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      showQualifyDialog: false,
      loading: false,
      deleteDisable: false,
    };
  },

  filters: {},

  mounted() {},

  methods: {
    // 新增一行
    onAddSalary() {},

    // 移除一行
    removeTableData(index) {
      this.tableData.splice(index, 1);
    },

    // 移除当前列数据事件
    onDeleteSalary(id) {
      this.$confirm(`是否删除该项目`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let submitData = { qualify_guid: id };
        this.deleteDisable = true;

        let { errcode, errmsg } = await Qualify.deleteQualify(submitData);

        await this.$nextTick(() => {
          if (errcode == 0) {
            this.$message.success("项目删除成功");
            this.$emit("success");
          } else {
            this.$message.error(errmsg);
          }
          this.deleteDisable = false;
        });
      });
    },

    onShowAddDialog(type, editData) {
      this.$refs.addQualifyDialog.onShowDialog(type, editData);
    },

    // 打开对话框
    onShowDialog() {
      this.showQualifyDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showQualifyDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.setting-qualify-dialog {
  position: relative;

  .setting-footer {
    position: absolute;
    bottom: 0;
    right: 20px;
    padding: 20px 0;
  }
}
</style>